import '../scss/main.scss';
import $ from 'jquery';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useRouter } from './route';
import App from '../vue/App.vue';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/bundle';

// Vue.js
createApp(App).use(createPinia()).use(useRouter).mount('#app');

// jQuery
$(function () {
  // drawer navi
  const iconMenu = $('.js-hamburger').attr('data-default');
  const iconClose = $('.js-hamburger').attr('data-close');
  $('.js-hamburger').on('click', function () {
    $('.js-drawer-navi').fadeToggle().toggleClass('__opened');
    if ($('.js-drawer-navi').hasClass('__opened')) {
      $('.js-hamburger .__image').attr('src', iconClose as string);
      $('html').css('overflow', 'hidden');
    } else {
      $('.js-hamburger .__image').attr('src', iconMenu as string);
      $('html').css('overflow', 'visible');
    }
  });
  $(window).on('resize', function () {
    $('.js-drawer-navi').fadeOut().removeClass('__opened');
    $('.js-hamburger .__image').attr('src', iconMenu as string);
    $('html').css('overflow', 'visible');
  });
});

// swiper
const swiper = new Swiper('.swiper', {
  slidesPerView: 1,
  spaceBetween: 24,
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  breakpoints: {
    769: {
      slidesPerView: 2
    }
  }
});
